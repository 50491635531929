module.exports = {
  title: 'Brian’s Musings', // Required
  author: 'Brian Krausz', // Required
  description: 'I build internet things',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    github: 'https://github.com/bkrausz',
    twitter: 'https://twitter.com/bkrausz',
    linkedin: 'https://linkedin.com/in/bkrausz',
  },
  pathPrefix: '/',
  siteUrl: 'https://briankrausz.com/',
};
